interface ShiftCarouselProps {
  carousel: any;
  index: number;
}

var carouselTouchStartPositionX: number = 0;

const handleCarouselNavigationClick: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const navigationItem: any = event?.currentTarget || event?.target;
    const navigationItemIndex: number = parseInt(navigationItem?.dataset?.index) || 0;
    const carousel: any = navigationItem?.closest('.carousel') || null;

    shiftCarouselToItemByIndex({ carousel, index: navigationItemIndex });
  } catch (error) {
    console.error(error);
  }
};

const handleCarouselTouchStart: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const carouselOuterTouchStartX: number = event?.touches[0]?.clientX || 0;

    carouselTouchStartPositionX = carouselOuterTouchStartX;
  } catch (error) {
    console.error(error);
  }
};

const handleCarouselTouchEnd: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const carouselOuter: any = event?.currentTarget || event?.target;
    const carouselOuterTouchEndX: number = event?.changedTouches[0]?.clientX || 0;
    const carousel = carouselOuter?.closest('.carousel') || null;
    const carouselItems: any = carouselOuter?.getElementsByClassName('item') || null;
    const carouselActiveItem: any = carouselOuter?.getElementsByClassName('item active')[0];
    const carouselActiveItemIndex: number = Array.prototype.indexOf.call(carouselItems, carouselActiveItem);

    if (carouselOuterTouchEndX < carouselTouchStartPositionX)
      shiftCarouselToItemByIndex({ carousel, index: carouselActiveItemIndex + 1 });

    if (carouselOuterTouchEndX > carouselTouchStartPositionX)
      shiftCarouselToItemByIndex({ carousel, index: carouselActiveItemIndex - 1 });

    carouselTouchStartPositionX = 0;
  } catch (error) {
    console.error(error);
  }
};

const shiftCarouselToItemByIndex: (props: ShiftCarouselProps) => void = ({ carousel, index }) => {
  try {
    const carouselInner: any = carousel?.getElementsByClassName('inner')[0];
    const carouselItems: any = carousel?.getElementsByClassName('item') || null;
    const carouselActiveItem: any = carouselInner?.getElementsByClassName('active')[0];
    const carouselActiveItemWidth: number = carouselActiveItem?.offsetWidth || 0;
    const carouselNavigationContainer: any = carousel?.getElementsByClassName('navigation')[0];
    const carouselNavigationItems: any = carouselNavigationContainer?.getElementsByClassName('item') || null;
    const carouselNavigationActiveItem: any = carouselNavigationContainer?.getElementsByClassName('active')[0];

    if (index < 0) index = carouselItems.length - 1;

    if (index > carouselItems.length - 1) index = 0;

    carouselInner.style.transform = `translateX(-${index * carouselActiveItemWidth}px)`;
    carouselActiveItem.classList.remove('active');
    carouselItems[index].classList.add('active');
    carouselNavigationActiveItem.classList.remove('active');
    carouselNavigationItems[index].classList.add('active');
  } catch (error) {
    console.error(error);
  }
};

const handleCarouselResize: () => void = () => {
  try {
    const carousels: any = document.getElementsByClassName('carousel');

    for (let i = 0; i < carousels.length; i++) {
      const carousel: any = carousels[i];

      shiftCarouselToItemByIndex({ carousel, index: 0 });
    }
  } catch (error) {
    console.error(error);
  }
};

window.addEventListener('resize', handleCarouselResize);
